import Background from '@src/images/bg.svg';
import styled from 'styled-components';

export const Info = styled.div`
    padding: 16px;
    flex: 1 1 auto;
    background-color: #0B0C11;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
`;

export const Name = styled.div`
    font-size: 20px;
    margin-bottom: 48px;
`;

export const Link = styled.a`
    color: #feae03;
    text-decoration: none;
    margin-bottom: 12px;
`;

export const Privacy = styled.div`
    margin-top: auto;
`;

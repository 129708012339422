import { TToastType } from '@src/app/types';
import { TTokenReceived } from '@common/events/types';
import { addCustomEventListener, dispatchCustomEvent } from '@common/events/events';
import { addToastAction, removeToastAction } from '@src/redux/slices/toastSlice';
import { getToken, setToken } from '@common/cookie';

let isGettingToken = false;

/**
 * Коды ошибок запросов.
 * @enum {string}
 */
export const RequestErrorCode = {
  /** Для подтверждения операции требуется код. */
  CONFIRMATION_CODE_REQUIRED: 'ERR_CONFIRMATION_CODE_REQUIRED',
  /** Требуется подтверждение операции. */
  CONFIRMATION_REQUIRED: 'ERR_CONFIRMATION_REQUIRED',
  /** Номер телефона уже привязан к другому пользователю. */
  PHONE_NOT_UNIQUE: 'ERR_PHONE_NOT_UNIQUE',
  /** Сервис занят. */
  SERVICE_IS_BUSY: 'ERR_SERVICE_IS_BUSY',
  /** Для подтверждения операции требуется мобильное приложение. */
  CONFIRMATION_MOBILE_REQUIRED: 'ERR_CONFIRMATION_MOBILE_REQUIRED',
  /** Пользователь отклонил запрос на подписание. */
  SIGN_REJECTED: 'ERR_SIGN_REJECTED',
};

// Функция получения токена.
async function requestToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    const token = getToken();
    if (!token) {
      if (isGettingToken) {
        addCustomEventListener<TTokenReceived>('onTokenReceived', event => {
          if (event.data.isError) {
            reject(event.data.errorResult);
          } else {
            resolve(event.data.result);
          }
        });
      } else {
        isGettingToken = true;
        const eventData = {
          data: {
            isError: false,
            result: '',
          },
        } as TTokenReceived;
        fetch('https://api-coin.playrole.ru/player/authentication', {
          method: 'POST',
          body: Telegram.WebApp.initData,
        })
          .then(response => {
            if (response.status !== 200) {
              throw response;
            }

            return response.json();
          })
          .then(result => {
            setToken(result);
            resolve(result);
            eventData.data.result = result;
          })
          .catch((err: Error) => {
            reject(err);
            eventData.data.isError = true;
            eventData.data.errorResult = err;
          })
          .finally(() => {
            isGettingToken = false;
            dispatchCustomEvent<TTokenReceived>('onTokenReceived', eventData);
          });
      }
    } else {
      resolve(token);
    }
  });
}

const serviceUnavailableMessages = [
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Preflight response is not successful',
  'Load failed',
];

export function getRequestErrorMessage(error: any): string {
  if (typeof error === 'string') {
    return error;
  }
  if (error.status === 503 || serviceUnavailableMessages.includes(error.message)) {
    return 'Сервис недоступен, повторите попытку позднее';
  }
  return error.message || error.statusText || 'Что-то пошло не так, повторите попытку позднее';
}

type TRequestOption = {
  signal?: RequestInit['signal'];
  method?: RequestInit['method'];
  body?: KeyValue<string|number>;
  token?: string;
  skipGettingToken?: boolean;
  showToast?:boolean;
}

export async function sendRequest<T>(url: string, options: TRequestOption, isRetry = false): Promise<T | null> {
  const abortController = new AbortController();
  options.signal?.addEventListener('abort', () => abortController.abort());
  const fullUrl = url.startsWith('/') ? `https://api-coin.playrole.ru${url}` : url;
  let timeoutID: ReturnType<typeof setTimeout> | null = null;

  let toastID: string;

  if (options.showToast) {
    toastID = addToastAction({
      text: 'Отправка запроса...',
      type: TToastType.INFO,
      closeOnTimeout: false,
    });
  }

  return new Promise<T | null>((resolve, reject) => {
    const processedResolve = (response: any) => {
      if (toastID) {
        removeToastAction(toastID);
        addToastAction({
          text: response.toastMessage || 'Успех!',
          type: TToastType.INFO,
          closeOnTimeout: true,
        });
      }
      if (response.status === 204) {
        resolve(null);
      } else {
        resolve(response);
      }
    };

    const formattedReject = (error: any) => {
      const err = typeof error === 'object' ? error : {};
      err.message = getRequestErrorMessage(error);
      if (toastID) {
        removeToastAction(toastID);
      }

      addToastAction({
        text: err.message,
        type: TToastType.ERROR,
        closeOnTimeout: true,
      });
      reject(err);
    };

    const rejectWithLog = (message: string, details: string) => {
      console.error(`${message}\n${details}`); // eslint-disable-line no-console
      formattedReject(message);
    };

    const rejectOnError = (error: any) => {
      if (error.status === 503) {
        error.json().then((err: any) => {
          if (err.code === RequestErrorCode.SERVICE_IS_BUSY) {
            console.log(`${err.reason} ${err.details}`);
            // showToast(`${err.reason} ${err.details}`, ToastType.ERROR, false, true);
          }
        });
      }
      if (error.status === 500) {
        error.json()
          .then((response: any) => formattedReject(response))
          .catch((err: any) => rejectWithLog('Что-то пошло не так, повторите попытку позднее', getRequestErrorMessage(err)));
      } else {
        formattedReject(error);
      }
    };

    const requestTimeout = 20;
    if (!Number.isNaN(requestTimeout) && requestTimeout > 0) {
      timeoutID = setTimeout(() => {
        abortController.abort();
        formattedReject('Превышено время ожидания ответа');
      }, requestTimeout * 1000);
    }

    const request = (token?: string) => {
      const requestOptions: RequestInit = {
        method: options.method || 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(options.body),
        signal: abortController.signal,
        cache: 'no-cache',
      };
      if (token) {
        requestOptions.headers = {
          ...requestOptions.headers,
          'Application-Authorization': token,
        };
      }
      fetch(fullUrl, requestOptions)
        .then(response => {
          if (!response.ok) {
            if (response.status === 401 && !isRetry) {
              setToken('');
              sendRequest(fullUrl, options, true)
                .then(result => processedResolve(result))
                .catch(rejectOnError);
            } else {
              rejectOnError(response);
            }
          } else {
            const contentType = response.headers.get('content-type');

            if (contentType && contentType.indexOf('application/json') !== -1) {
              response.json()
                .then(json => processedResolve(json))
                .catch(err => rejectWithLog('Что-то пошло не так, повторите ', getRequestErrorMessage(err)));
            } else {
              processedResolve(response);
            }
          }
        })
        .catch(error => {
          if (abortController.signal.aborted) {
            reject();
          } else {
            rejectOnError(error);
          }
        });
    };

    if (options.token || options.skipGettingToken) {
      request(options.token);
    } else {
      requestToken()
        .then((token: string) => request(token))
        .catch(err => rejectWithLog('Ошибка авторизации', getRequestErrorMessage(err)));
    }
  }).finally(() => {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  });
}

import * as React from 'react';
import * as S from './Loader.styled';
import { FC, memo } from 'react';
import Logo from '@src/images/logo.png';

const Loader: FC = () => (
  <S.Loader>
    <img
      width={260}
      src={Logo}
      alt=""
    />
    <S.LoaderText>
      Загрузка...
    </S.LoaderText>
  </S.Loader>
);

export default memo(Loader);

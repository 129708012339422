import * as S from './MainLoader.styled';
import React, { FC, memo } from 'react';

type IProps = {
  width: number;
  height: number;
  children?: React.ReactNode;
};

const MainLoader: FC<IProps> = ({ width, height, children }) => (
  <S.MainLoader
    width={width}
    height={height}
  >
    {children}
  </S.MainLoader>
);

export default memo(MainLoader);

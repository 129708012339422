import Background from '@src/images/bg.svg';
import styled from 'styled-components';

export const Friends = styled.div`
    padding: 16px;
    flex: 1 1 auto;
    background-color: #0B0C11;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
`;

export const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
`;

export const HeaderText = styled.div`
    font-size: 14px;
    margin-bottom: 42px;
    text-align: center;
`;

export const HeaderItem = styled.div`
    background-color: #2B2E42;
    padding: 4px;
    border-radius: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
`;

export const HeaderItemBody = styled.div`
margin-left: 12px;
`;

export const HeaderItemTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 8px;
`;

export const HeaderItemReward = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 700;
    color: #FCA100;
`;

export const HeaderItemText = styled.div`
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: stretch;
    gap: 16px;
    margin: 42px 0;
`;

export const ShareButton = styled.button`
    background-color: #feae03;
    flex: 1 1 auto;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 24px;
    color: #1E202D;
    border-radius: 16px;
`;

export const CopyButton = styled.button`
    background-color: #feae03;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1F2C53;
    border-radius: 16px;
`;

export const ReferralTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 24px;
`;

export const ReferralContent = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const ReferralItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    
    &:before {
        left: 6px;
        top: 0.5em;
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        background-color: #FCA100;
        border-radius: 50%;
    }
`;

export const ReferralItemIncome = styled.span`
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    
    img {
        margin-right: 4px;
    }
`;

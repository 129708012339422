import * as S from './Exchange.styled';
import { TShare } from '@src/app/types';
import { fetchShares, selectHistory,
  selectShareLoading,
  selectShares } from '@src/redux/slices/shareSlice';
import { getHumanBalance } from '@common/number';
import { useAppDispatch } from '@common/hooks';
import { useSelector } from 'react-redux';
import Coin from '@src/images/coin.png';
import React, { FC, memo, useEffect, useState } from 'react';
import Share from './Share';
import ShareGridLoader from '@components/Exchange/ShareGridLoader';
import ShareModal from '@components/Exchange/ShareModal';

const Exchange: FC = () => {
  const dispatch = useAppDispatch();
  const shares = useSelector(selectShares);
  const history = useSelector(selectHistory);
  const loading = useSelector(selectShareLoading);
  const [sortedShares, setSortedShares] = useState({
    easy: [] as TShare[],
    normal: [] as TShare[],
    hard: [] as TShare[],
  });

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchShares());
    return () => fetchingDispatch?.abort();
  }, [dispatch]);

  useEffect(() => {
    if (shares) {
      setSortedShares({
        easy: shares.filter(share => share.risk === 'EASY'),
        normal: shares.filter(share => share.risk === 'NORMAL'),
        hard: shares.filter(share => share.risk === 'HARD'),
      });
    }
  }, [shares]);

  return (
    <S.Exchange>
      <S.Balance />
      <S.ContentWrapper>
        <S.Text>
          Для увеличения капитала, вы можете вложиться в акции различных компаний.
          Если цены акций вырастут в течении суток после покупки, то вы увеличите свой капитал.
          Но будьте осторожны, если цены акций упадут, вы потеряете все вложения.
        </S.Text>
        {loading
          ? (
            <ShareGridLoader />
          )
          : (
            <>
              <S.SubTitle>Минимальный риск</S.SubTitle>
              <S.GridScroll>
                <S.Grid $itemCount={sortedShares.easy.length}>
                  {sortedShares.easy.map(share => (
                    <Share
                      key={share.id}
                      share={share}
                    />
                  ))}
                </S.Grid>
              </S.GridScroll>
              <S.SubTitle>Средний риск</S.SubTitle>
              <S.GridScroll>
                <S.Grid $itemCount={sortedShares.normal.length}>
                  {sortedShares.normal.map(share => (
                    <Share
                      key={share.id}
                      share={share}
                    />
                  ))}
                </S.Grid>
              </S.GridScroll>
              <S.SubTitle>Высокий риск</S.SubTitle>
              <S.GridScroll>
                <S.Grid $itemCount={sortedShares.hard.length}>
                  {sortedShares.hard.map(share => (
                    <Share
                      key={share.id}
                      share={share}
                    />
                  ))}
                </S.Grid>
              </S.GridScroll>
              <S.SubTitle>Последние 20 сделок</S.SubTitle>
              {history.length ? (
                <S.HistoryGrid>
                  {history.map(share => (
                    <S.History key={share.id}>
                      <S.HistoryName>
                        {share.name}
                      </S.HistoryName>
                      <S.HistoryResult $isSuccess={share.status === 'SUCCESS'}>
                        {share.status === 'SUCCESS' ? '+' : '-'}
                        <img
                          width={14}
                          src={Coin}
                          alt=""
                        />
                        {share.status === 'SUCCESS' ? getHumanBalance(share.income) : getHumanBalance(share.price * share.count)}
                      </S.HistoryResult>
                    </S.History>
                  ))}
                </S.HistoryGrid>
              ) : (
                <S.HistoryMessage>Вы ещё не совершали сделок</S.HistoryMessage>
              )}
            </>
          )}
      </S.ContentWrapper>
      <ShareModal />
    </S.Exchange>
  );
};

export default memo(Exchange);

import * as S from './Info.styled';
import { selectReferralLink } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import React, { FC, memo } from 'react';

const Info: FC = () => {
  const referralLink = useSelector(selectReferralLink);

  return (
    <S.Info>
      <S.Title>Роль Капитал</S.Title>
      <S.Name>ID {referralLink}</S.Name>
      <S.Link
        href="mailto:game@playrole.ru?subject=Вопрос по игре Роль Капитал"
        target="_blank"
        rel="noreferrer"
      >Связь с поддержкой
      </S.Link>
      <S.Link
        href="//playrole.ru/capital-rules"
        target="_blank"
        rel="noreferrer"
      >Правила игры
      </S.Link>
      <S.Link
        href="//playrole.ru/capital-agreement"
        target="_blank"
        rel="noreferrer"
      >Лицензионное соглашение
      </S.Link>
      <S.Privacy>© 2024, Роль</S.Privacy>
    </S.Info>
  );
};

export default memo(Info);

import * as React from 'react';
import * as S from './Quest.styled';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { TPlayer, TTask } from '@src/app/types';
import { fetchTasks, selectTaskLoading, selectTasks, setTasks } from '@src/redux/slices/taskSlice';
import { getHumanBalance } from '@common/number';
import { sendRequest } from '@src/network';
import { setPlayer } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Close from '@src/images/close.svg';
import Coin from '@src/images/coin.png';
import QuestGridLoader from '@components/Quests/QuestGridLoader';
import Right from '@src/images/right-yellow.svg';

const Quests: FC = () => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const tasks = useSelector(selectTasks);
  const loading = useSelector(selectTaskLoading);
  const [currentTask, setCurrentTask] = useState<TTask>();
  const [sortedTasks, setSortedTasks] = useState({
    new: [] as TTask[],
    in_process: [] as TTask[],
    done: [] as TTask[],
  });

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchTasks());
    return () => fetchingDispatch.abort();
  }, [dispatch]);

  useEffect(() => {
    if (tasks) {
      setSortedTasks({
        new: tasks
          .filter(task => task.status === 'NEW')
          .filter(task => (
            !tasks.some(value => value.status !== 'DONE' && task.action === value.action && task.id > value.id)
          )),
        in_process: tasks.filter(task => task.status === 'IN_PROCESS'),
        done: tasks
          .filter(task => task.status === 'DONE')
          .filter(task => (
            !tasks.some(value => value.status === 'DONE' && task.action === value.action && task.id < value.id)
          )),
      });
    }
  }, [tasks]);

  const clickCallback = useCallback(() => {
    const completingTask = { ...currentTask };
    setCurrentTask(undefined);
    if (completingTask?.id) {
      sendRequest<{ tasks: Array<TTask>; player: TPlayer}>('/task/complete', {
        method: 'POST',
        body: {
          id: completingTask.id,
        },
        showToast: true,
      })
        .then(response => {
          if (response) {
            dispatch(setTasks(response.tasks));
            dispatch(setPlayer(response.player));
          }
        })
        .catch(reason => console.log(reason?.message));
    }
  }, [currentTask, dispatch]);

  return (
    <S.Quest>
      <S.Title>Заработай больше монет</S.Title>
      {loading
        ? <QuestGridLoader />
        : (
          <>
            {!!sortedTasks.new.length && (
              <>
                <S.SubTitle>Новые задания</S.SubTitle>
                <S.TaskGrid>
                  {sortedTasks.new.map(task => (
                    <S.Task
                      key={task.id}
                      onClick={() => {
                        if (task.status !== 'DONE') {
                          setCurrentTask(task);
                        }
                      }}
                    >
                      <S.TaskName>{task.name}</S.TaskName>
                      <S.TaskReward>
                        <span>+</span>
                        <img
                          width={14}
                          src={Coin}
                          alt=""
                        />
                        <span>{getHumanBalance(task.reward)}</span>
                      </S.TaskReward>
                      <S.TaskButton>
                        <img
                          height={14}
                          src={Right}
                          alt=""
                        />
                      </S.TaskButton>
                    </S.Task>
                  ))}
                </S.TaskGrid>
              </>
            )}
            {!!sortedTasks.in_process.length && (
              <>
                <S.SubTitle>В процессе</S.SubTitle>
                <S.TaskGrid>
                  {sortedTasks.in_process.map(task => (
                    <S.Task
                      key={task.id}
                      onClick={() => {
                        if (task.status !== 'DONE') {
                          setCurrentTask(task);
                        }
                      }}
                    >
                      <S.TaskName>{task.name}</S.TaskName>
                      <S.TaskReward>
                        <span>+</span>
                        <img
                          width={14}
                          src={Coin}
                          alt=""
                        />
                        <span>{getHumanBalance(task.reward)}</span>
                      </S.TaskReward>
                      <S.TaskButton>
                        <img
                          height={14}
                          src={Right}
                          alt=""
                        />
                      </S.TaskButton>
                    </S.Task>
                  ))}
                </S.TaskGrid>
              </>
            )}
            {!!sortedTasks.done.length && (
              <>
                <S.SubTitle>Выполненные</S.SubTitle>
                <S.TaskGrid>
                  {sortedTasks.done.map(task => (
                    <S.Task
                      key={task.id}
                      $isDone
                    >
                      <S.TaskName>{task.name}</S.TaskName>
                      <S.TaskReward>
                        <span>+</span>
                        <img
                          width={14}
                          src={Coin}
                          alt=""
                        />
                        <span>{getHumanBalance(task.reward)}</span>
                      </S.TaskReward>
                    </S.Task>
                  ))}
                </S.TaskGrid>
              </>
            )}
          </>
        )}
      {currentTask ? (
        <S.ModalCard>
          <S.ModalCardBody>
            <S.ModalCardClose onClick={() => {
              impactOccurred('soft');
              setCurrentTask(undefined);
            }}
            >
              <img
                width={14}
                src={Close}
                alt=""
              />
            </S.ModalCardClose>
            {currentTask.image && (
              <S.ModalCardImage src={`${currentTask.image}?v=${window.appVersion}`} />
            )}
            <S.ModalCardName>{currentTask.name}</S.ModalCardName>
            {currentTask.description && (
              <S.ModalCardDescription>{currentTask.description}</S.ModalCardDescription>
            )}
            <S.ModalCardPrice>
              <span>+</span>
              <img
                width={14}
                src={Coin}
                alt=""
              />
              <span>{getHumanBalance(currentTask.reward)}</span>
            </S.ModalCardPrice>
            {currentTask.status === 'NEW' ? (
              <div>
                {currentTask.action === 'REFERRAL'
                  || currentTask.action === 'REFERRAL_LEVEL'
                  || currentTask.action === 'LEVEL' ? (
                    <S.ModalCardLink
                      to={currentTask.action === 'REFERRAL' || currentTask.action === 'REFERRAL_LEVEL' ? '/friends' : '/'}
                      onClick={() => clickCallback()}
                    >
                      {currentTask.action_name}
                    </S.ModalCardLink>
                  ) : (
                    <S.ModalCardBtn
                      href={currentTask.action}
                      target="_blank"
                      onClick={() => clickCallback()}
                    >
                      {currentTask.action_name}
                    </S.ModalCardBtn>
                  )}
              </div>
            ) : (
              <S.ModalCardBtn onClick={() => {
                window.ym(98334776, 'reachGoal', 'TASK_CHECK', {
                  order_price: currentTask.reward,
                  currency: 'RUB',
                });
                clickCallback();
              }}
              >
                Проверить
              </S.ModalCardBtn>
            )}
          </S.ModalCardBody>
        </S.ModalCard>
      ) : null}
    </S.Quest>
  );
};

export default memo(Quests);

import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { FC, memo } from 'react';
import { TUpgradeCard } from '@src/app/types';
import { getHumanBalance } from '@common/number';
import { setCurrentCard } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import BlankCard from '@src/images/blank-card.jpg';
import CardRequirement from '@components/Investment/CardRequirement';
import Coin from '@src/images/coin.png';

type TProps = {
  card: TUpgradeCard;
}

const Card: FC<TProps> = ({ card }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Card
      onClick={() => {
        impactOccurred('soft');
        dispatch(setCurrentCard(card));
      }}
    >
      <CardRequirement card={card} />
      <S.CardLevel>lvl {card.level}</S.CardLevel>
      <S.CardImage src={card.image ? `${card.image}?v=${window.appVersion}` : BlankCard} />
      <S.CardName>{card.name}</S.CardName>
      <S.CardContent>
        <S.CardIncomeLabel>Доход в&nbsp;час</S.CardIncomeLabel>
        <S.CardIncome>
          <img
            width={14}
            src={Coin}
            alt=""
          />
          <span>{getHumanBalance(card.total_income)}</span>
        </S.CardIncome>
      </S.CardContent>
      <S.CardFooter>
        <img
          width={14}
          src={Coin}
          alt=""
        />
        <span>{getHumanBalance(card.price)}</span>
      </S.CardFooter>
    </S.Card>
  );
};

export default memo(Card);

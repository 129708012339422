import Background from '@src/images/bg.svg';
import styled from 'styled-components';

export const Loader = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0B0C11;
    padding: 16px;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
`;

export const LoaderText = styled.div`
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
`;

import * as React from 'react';
import { FC, memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchPlayer, passiveEarn, selectPlayerLoading } from '@src/redux/slices/playerSlice';
import { useAppDispatch } from '@common/hooks';
import { useSelector } from 'react-redux';
import Loader from '@components/Loader';
import Navigation from '@components/Navigation/Navigation';

const Layout: FC = () => {
  const dispatch = useAppDispatch();
  const playerLoading = useSelector(selectPlayerLoading);

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchPlayer());
    return () => fetchingDispatch.abort();
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(passiveEarn());
    }, 1000);

    return () => clearTimeout(intervalId);
  }, [dispatch]);

  if (playerLoading) {
    return <Loader />;
  }

  return (
    <>
      <Outlet />
      <Navigation />
    </>
  );
};

export default memo(Layout);

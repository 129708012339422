import styled from 'styled-components';

export const Toast = styled.div`
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    background-color: #505050db;
    border-radius: 12px;
    animation: toast-appear .5s;
    pointer-events: auto;

    @keyframes toast-appear {
        0% {
            opacity: 0;
            transform: translate(0, -2rem);
        }

        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
`;

export const Button = styled.button`
  margin-left: auto;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

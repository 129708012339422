import * as React from 'react';
import { FC, memo } from 'react';
import ContentLoader from 'react-content-loader';
import MainLoader from '@components/MainLoader';

const width = 344;
const height = 488;

const CardGridLoader: FC = () => (
  <MainLoader
    width={width}
    height={height}
  >
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title="Загрузка..."
      backgroundColor="#2B2E42"
      foregroundColor="#3e4259"
    >
      <rect
        x="0"
        y="0"
        rx="24"
        ry="24"
        width="344"
        height="48"
      />
      <rect
        x="0"
        y="80"
        rx="8"
        ry="8"
        width="168"
        height="200"
      />
      <rect
        x="176"
        y="80"
        rx="8"
        ry="8"
        width="168"
        height="200"
      />
      <rect
        x="0"
        y="288"
        rx="8"
        ry="8"
        width="168"
        height="200"
      />
      <rect
        x="176"
        y="288"
        rx="8"
        ry="8"
        width="168"
        height="200"
      />
    </ContentLoader>
  </MainLoader>
);

export default memo(CardGridLoader);

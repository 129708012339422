import { NavLink } from 'react-router-dom';
import Background from '@src/images/bg.svg';
import styled, { css } from 'styled-components';

export const Quest = styled.div`
    padding: 16px;
    flex: 1 1 auto;
    background-color: #0B0C11;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 42px;
    text-align: center;
`;

export const SubTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 16px;
`;

export const TaskGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 16px 0 32px;
    flex: 1 1 auto;
`;

type TTask = {
  $isDone?: boolean;
};

export const Task = styled.div<TTask>`${({ $isDone = false }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 100px 35px;
    gap: 8px;
    padding: 12px;
    border-radius: 16px;
    box-shadow: 2px 2px 8px 0 #00000059;
    background-color: #2B2E42;
    opacity: ${$isDone ? '0.8' : '1'};
`}`;

export const TaskName = styled.div`
    font-size: 12px;
    font-weight: 500;
`;

export const TaskReward = styled.div`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const TaskButton = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1E202D;
    border-radius: 10px;
`;

export const ModalCard = styled.div`
    position: fixed;
    z-index: 120;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
`;

export const ModalCardBody = styled.div`
    margin-top: auto;
    background-color: #2B2E42;
    border-radius: 24px 24px 0 0;
    padding: 16px 16px 48px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    animation: fadeIn 0.3s;

    @keyframes fadeIn {
        0% {
            transform: translate(0, 100%);
        }

        100% {
            transform: translate(0, 0);
        }
    }
`;

export const ModalCardClose = styled.button`
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
`;

export const ModalCardImage = styled.img`
    display: block;
    margin-bottom: 16px;
    border-radius: 16px;
`;

export const ModalCardName = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
`;

export const ModalCardDescription = styled.div`
    font-size: 16px;
    margin-bottom: 16px;
`;

export const ModalCardPrice = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export const ModalCardBtn = styled.a`
    display: block;
    width: 100%;
    font-size: 18px;
    padding: 16px 24px;
    color: #1F2C53;
    border-radius: 12px;
    background-color: #feae03;
`;

export const ModalCardLink = styled(NavLink)`
    display: block;
    width: 100%;
    font-size: 18px;
    padding: 16px 24px;
    color: #1F2C53;
    border-radius: 12px;
    background-color: #feae03;
`;

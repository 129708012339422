import Background from '@src/images/bg.svg';
import PlayerBalance from '@components/PlayerBalance';
import styled, { css } from 'styled-components';

export const Boost = styled.div`
    flex: 1 1 auto;
`;

export const BoostTitle = styled.div`
    margin-bottom: 16px;
`;

export const Balance = styled(PlayerBalance)`
    display: flex;
    padding-top: 24px;
    margin-bottom: 24px;
    position: sticky;
    top: 0;
    z-index: 0;
`;

export const ContentWrapper = styled.div`
    position: relative;
    z-index: 5;
    padding: 16px 16px 48px;
    border-radius: 32px 32px 0 0;
    background-color: #1E202D;
    border-top: 2px solid #65450D;
    box-shadow: 0 55px 56px 50px #F29E099E;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    
    margin-bottom: -24px;
`;

export const BoostItem = styled.div`
    padding: 24px 16px 20px;
    background-color:#2B2E42;
    border-radius: 16px;
    margin-bottom: 16px;
`;

export const CharacterItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 16px 0;
    &+& {
        border-top: 1px dashed #3B3F59;
    }
`;

export const CharacterLabel = styled.div`
    font-size: 12px;
    font-weight: 400;
`;

export const CharacterValue = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #FCA100;
`;

export const BoostCard = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 42px 70px 30px;
    gap: 12px;
    padding: 8px 0;
    &+& {
        border-top: 1px dashed #3B3F59;
    }
`;

export const BoostName = styled.div`
    font-size: 12px;
    font-weight: 400;
`;

export const BoostValue = styled.div`
    color: #FCA100;
    background-color: #1E202D;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 10px;
`;

export const BoostReward = styled.div`
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
`;

type TBoostBtn = {
  $disabled: boolean;
}

export const BoostBtn = styled.button<TBoostBtn>`${({ $disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background-color: #1E202D;
    border-radius: 10px;
    pointer-events: ${$disabled ? 'none' : 'auto'};
    opacity: ${$disabled ? '0.5' : '1'};
`}`;

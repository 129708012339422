import styled from 'styled-components';

export const Balance = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const BalanceText = styled.div`
    font-size: 32px;
    line-height: 1;
    font-weight: 600;
    color: #fff;
    margin-left: 16px;
`;

import * as React from 'react';
import { FC, memo } from 'react';
import ContentLoader from 'react-content-loader';
import MainLoader from '@components/MainLoader';

const width = 344;
const height = 292;

const RatingGridLoader: FC = () => (
  <MainLoader
    width={width}
    height={height}
  >
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title="Загрузка..."
      backgroundColor="#2B2E42"
      foregroundColor="#3e4259"
    >
      <rect
        x="0"
        y="0"
        rx="4"
        ry="4"
        width="344"
        height="18"
      />
      <rect
        x="0"
        y="42"
        rx="8"
        ry="8"
        width="344"
        height="55"
      />
      <rect
        x="0"
        y="107"
        rx="8"
        ry="8"
        width="344"
        height="55"
      />
      <rect
        x="0"
        y="172"
        rx="8"
        ry="8"
        width="344"
        height="55"
      />
      <rect
        x="0"
        y="237"
        rx="8"
        ry="8"
        width="344"
        height="55"
      />
    </ContentLoader>
  </MainLoader>
);

export default memo(RatingGridLoader);

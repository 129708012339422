import styled from 'styled-components';

type TMainLoader = {
  width: number;
  height: number;
};

export const MainLoader = styled.div<TMainLoader>`
  position: relative;
  padding-bottom: ${({ width, height }) => `${(height / width) * 100}%`};

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

import * as React from 'react';
import * as S from '@components/Boost/Boost.styled';
import { FC, memo, useMemo } from 'react';
import { selectEarnPerTap,
  selectEnergyLevel,
  selectEnergyRecoverLevel, selectMaxPassiveEarnTime, selectMaxTaps,
  selectPassiveEarnLevel,
  selectTapLevel, selectTapsRecoverPerSec } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import BoostCard from '@components/Boost/BoostCard';

const Boost: FC = () => {
  const tapLevel = useSelector(selectTapLevel);
  const energyLevel = useSelector(selectEnergyLevel);
  const energyRecoverLevel = useSelector(selectEnergyRecoverLevel);
  const passiveEarnLevel = useSelector(selectPassiveEarnLevel);
  const maxPassiveEarnTime = useSelector(selectMaxPassiveEarnTime);
  const maxTaps = useSelector(selectMaxTaps);
  const earnPerTap = useSelector(selectEarnPerTap);
  const tapsRecoverPerSec = useSelector(selectTapsRecoverPerSec);

  const tapPrice = useMemo(() => 10000 * 2 ** tapLevel, [tapLevel]);
  const energyPrice = useMemo(() => 10000 * 2 ** energyLevel, [energyLevel]);
  const energyRecoverPrice = useMemo(() => 10000 * 2 ** energyRecoverLevel, [energyRecoverLevel]);
  const passiveEarnPrice = useMemo(() => 10000 * 2 ** passiveEarnLevel, [passiveEarnLevel]);

  return (
    <S.Boost>
      <S.Balance />
      <S.ContentWrapper>
        <S.BoostItem>
          <S.BoostTitle>Текущие характеристики</S.BoostTitle>
          <S.CharacterItem>
            <S.CharacterLabel>Доход за клик:</S.CharacterLabel>
            <S.CharacterValue>{earnPerTap}</S.CharacterValue>
          </S.CharacterItem>
          <S.CharacterItem>
            <S.CharacterLabel>Максимальная энергия:</S.CharacterLabel>
            <S.CharacterValue>{maxTaps}</S.CharacterValue>
          </S.CharacterItem>
          <S.CharacterItem>
            <S.CharacterLabel>Восстановление энергии:</S.CharacterLabel>
            <S.CharacterValue>{tapsRecoverPerSec} в секунду</S.CharacterValue>
          </S.CharacterItem>
          <S.CharacterItem>
            <S.CharacterLabel>Длительность дохода оффлайн:</S.CharacterLabel>
            <S.CharacterValue>{maxPassiveEarnTime / 60} мин</S.CharacterValue>
          </S.CharacterItem>
        </S.BoostItem>
        <S.BoostItem>
          <S.BoostTitle>Улучшения</S.BoostTitle>
          <BoostCard
            id="tap_level"
            name="Доход за клик"
            value="+ 1"
            price={tapPrice}
            level={tapLevel}
          />
          <BoostCard
            id="energy_level"
            name="Максимальная энергия"
            value="+ 500"
            price={energyPrice}
            level={energyLevel}
          />
          <BoostCard
            id="energy_recover_level"
            name="Восстановление энергии"
            value="+ 0,5"
            price={energyRecoverPrice}
            level={energyRecoverLevel}
          />
          <BoostCard
            id="passive_earn_level"
            name="Длительность дохода оффлайн"
            value="+ 5"
            price={passiveEarnPrice}
            level={passiveEarnLevel}
          />
        </S.BoostItem>
      </S.ContentWrapper>
    </S.Boost>
  );
};

export default memo(Boost);

import * as React from 'react';
import * as S from '@components/Mining/Mining.styled';
import { FC, memo, useMemo } from 'react';
import { selectFirstName,
  selectLastName,
  selectReferralLink,
  selectUsername } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';

const HeaderName: FC = () => {
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const username = useSelector(selectUsername);
  const referralLink = useSelector(selectReferralLink);

  const playerName = useMemo(() => {
    if (firstName && lastName) {
      return `${lastName} ${firstName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (username) {
      return username;
    }
    return `Игрок #${referralLink}`;
  }, [firstName, lastName, referralLink, username]);

  return (
    <S.HeaderName>
      {playerName}
    </S.HeaderName>
  );
};

export default memo(HeaderName);

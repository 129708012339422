import * as React from 'react';
import { FC, memo } from 'react';
import ContentLoader from 'react-content-loader';
import MainLoader from '@components/MainLoader';

const width = 344;
const height = 290;
const ReferralListLoader: FC = () => (
  <MainLoader
    width={width}
    height={height}
  >
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title="Загрузка..."
      backgroundColor="#2B2E42"
      foregroundColor="#3e4259"
    >
      <rect
        x="0"
        y="0"
        rx="4"
        ry="4"
        width="344"
        height="16"
      />
      <rect
        x="0"
        y="40"
        rx="4"
        ry="4"
        width="320"
        height="14"
      />
      <rect
        x="0"
        y="66"
        rx="4"
        ry="4"
        width="320"
        height="14"
      />
      <rect
        x="0"
        y="92"
        rx="4"
        ry="4"
        width="320"
        height="14"
      />
    </ContentLoader>
  </MainLoader>
);

export default memo(ReferralListLoader);

import { useNavigate } from 'react-router-dom';
import Layout from '@components/Layout';
import React, { FC, memo, useEffect } from 'react';
import ToastStack from '@components/Toast/ToastStack';

const App: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const callback = () => navigate(-1);
    Telegram.WebApp.BackButton.onClick(callback);

    return () => {
      Telegram.WebApp.BackButton.offClick(callback);
    };
  }, [navigate]);

  return (
    <>
      <ToastStack />
      <Layout />
    </>
  );
};

export default memo(App);

import { TTask } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TTaskState = {
  tasks: Array<TTask>;
  loading: boolean;
}

export const taskSlice = createAppSlice({
  name: 'task',
  initialState: {
    tasks: [],
    loading: true,
  } as TTaskState,
  reducers: create => ({
    setTasks: create.reducer<Array<TTask>>((state, action) => ({
      ...state,
      tasks: action.payload,
    })),
    fetchTasks: create.asyncThunk(
      async (_, { signal }) => sendRequest<Array<TTask>>('/task', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => {
          state.tasks = action.payload || [];
          state.loading = false;
        },
      },
    ),
  }),
  selectors: {
    selectTasks: state => state.tasks,
    selectTaskLoading: state => state.loading,
  },
});

export const { setTasks, fetchTasks } = taskSlice.actions;
export const { selectTasks, selectTaskLoading } = taskSlice.selectors;

export default taskSlice.reducer;

import styled from 'styled-components';

export const Timer = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    color: #FCA100;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
`;

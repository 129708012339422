import { NavLink } from 'react-router-dom';
import Background from '@src/images/bg.svg';
import PlayerBalance from '@components/PlayerBalance';
import styled, { css } from 'styled-components';

export const Mining = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const Header = styled.div`
    padding: 24px 16px 16px;
    position: sticky;
    top: 0;
    z-index: 0;
`;

export const HeaderGrid = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px dashed #6B6B6B;
`;

export const InfoButton = styled(NavLink)`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HeaderName = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
`;

export const HeaderBar = styled.div`
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 16px;
    margin-top: 12px;
`;

export const Income = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: #D9D9D926;
    border-radius: 24px;
    border: 1px solid #FFFFFF52;
`;

export const IncomeLabel = styled.div`
    font-size: 10px;
    line-height: 1;
    margin-left: 6px;
`;

export const IncomeValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    margin-left: 8px;
`;

export const Content = styled.div`
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-radius: 32px 32px 0 0;
    background-color: #1E202D;
    padding: 0 16px 24px;
    border-top: 2px solid #65450D;
    box-shadow: 0 55px 56px 50px #F29E099E;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    
    margin-bottom: -24px;
`;

export const BalanceWrapper = styled.div`
    margin: auto 0;
    text-align: center;
`;

export const Balance = styled(PlayerBalance)`
    margin: 32px auto;
    background: #1E202D;
    box-shadow: 0 2px 4px 0 #00000040 inset;
    padding: 12px;
    border-radius: 32px;
`;

export const TapContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0 0;
    touch-action: none;
    -webkit-touch-callout: none;
`;

export const TapHeader = styled.div`
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
`;

type TTapButton = {
  $isTaping: boolean;
}

export const TapButton = styled.button<TTapButton>`${({ $isTaping }) => css`
    display: flex;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    color: #fff;
    transform: ${$isTaping ? 'scale(.95)' : 'scale(1)'};
    border-radius: 50%;
    box-shadow: ${$isTaping ? '0 8px 8px 0 #000000BD' : '0 8px 16px 4px #000000BD'};
`}`;

export const TapFooter = styled.div`
    margin: 24px 0;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Energy = styled.div`
    display: flex;
    align-items: center;
    margin-right: 27px;
`;

export const EnergyText = styled.div`
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
`;

export const Boost = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #fff;
    text-align: center;
`;

export const BoostText = styled.span`
    margin-top: 4px;
    font-size: 12px;
`;

export const Animate = styled.div`
    pointer-events: none;
    user-select: none;

    div {
        position: fixed;
        z-index: 10;
        font-size: 32px;
        font-weight: 800;
        animation: appear 1s;

        @keyframes appear {
            0% {
                opacity: 1;
                transform: translate(0, 0);
            }

            100% {
                opacity: 0;
                transform: translate(0, -8rem);
            }
        }
    }
`;

import * as React from 'react';
import * as S from './Exchange.styled';
import { FC, memo } from 'react';
import { TShare } from '@src/app/types';
import { checkEnoughMoney } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import { useTimer } from '@common/hooks';
import Timer from '@components/Timer';

type TProps = {
  share: TShare;
}

const ShareRequirement: FC<TProps> = ({ share }) => {
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, share.price));
  const timer = useTimer(share.timeout);

  if (share.timeout && timer) {
    return (
      <S.ShareRequirement>
        <S.ShareRequirementTimer>Результат через:</S.ShareRequirementTimer>
        <Timer timer={timer} />
      </S.ShareRequirement>
    );
  }

  if (share.status === 'IN_PROCESS') {
    return <S.ShareRequirement>Посмотрите результат</S.ShareRequirement>;
  }

  if (!hasEnoughMoney) {
    return <S.ShareRequirement>Недостаточно средств</S.ShareRequirement>;
  }

  return null;
};

export default memo(ShareRequirement);

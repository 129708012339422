import * as S from '@components/Investment/Investments.styled';
import { TBuyCard, buyCard, selectBuyingCard } from '@src/redux/slices/cardSlice';
import { TUpgradeCard } from '@src/app/types';
import { checkEnoughMoney, setPlayer } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback, useTimer } from '@common/hooks';
import { useSelector } from 'react-redux';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import Timer from '@components/Timer';

type TProps = {
  card: TUpgradeCard;
}

type TButtonState = {
  text: string | React.JSX.Element;
  disabled: boolean;
}

const BuyButton: FC<TProps> = ({ card }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const buyingCard = useSelector(selectBuyingCard);
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, card.price));
  const timer = useTimer(card.timeout_at);
  const [buttonState, setButtonState] = useState<TButtonState>({
    text: 'Купить',
    disabled: true,
  });

  const buyCardCallback = useCallback(() => {
    impactOccurred('soft');

    const fetchingDispatch = dispatch(buyCard(card));
    fetchingDispatch.then(response => {
      const player = (response.payload as TBuyCard)?.player;
      if (player) {
        dispatch(setPlayer(player));
      }
    });
  }, [card, dispatch, impactOccurred]);

  useEffect(() => {
    if (!hasEnoughMoney) {
      setButtonState({
        text: 'Недостаточно средств',
        disabled: true,
      });
    } else if (card.requirement) {
      setButtonState({
        text: card.requirement,
        disabled: true,
      });
    } else if (card.timeout_at && timer) {
      setButtonState({
        text: <Timer timer={timer} />,
        disabled: true,
      });
    } else if (buyingCard) {
      setButtonState({
        text: 'Покупается',
        disabled: true,
      });
    } else {
      setButtonState({
        text: 'Купить',
        disabled: false,
      });
    }
  }, [buyingCard, card.requirement, card.timeout_at, hasEnoughMoney, timer]);

  return (
    <S.ModalCardBtn
      onClick={() => {
        if (!buttonState.disabled) {
          buyCardCallback();
        }
      }}
      disabled={buttonState.disabled}
    >
      {buttonState.text}
    </S.ModalCardBtn>
  );
};

export default memo(BuyButton);

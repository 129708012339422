export type TPlayer = {
  first_name?: string;
  last_name?: string;
  username?: string;
  telegram_id: number;
  balance_coins: number;
  total_coins: number;
  level: number;
  available_taps: number;
  max_taps: number;
  earn_per_tap: number;
  earn_passive: number;
  earn_passive_per_sec: number;
  last_passive_earn: string;
  taps_recover_per_sec: number;
  referral_link: string;
  daily_streak: number;
  tap_level: number;
  energy_level: number;
  energy_recover_level: number;
  passive_earn_level: number;
  max_passive_earn_time: number;
}

export type TUpgradeCard = {
  id: number;
  name: string;
  description: string;
  price: number;
  image: string;
  income: number;
  total_income: number;
  level: number;
  category: string;
  requirement?: string;
  expired_at?: number;
  timeout_at?: number;
}

export type TUpgradeCategory = {
  id: number;
  name: string;
  description: string;
  cards: Array<TUpgradeCard>;
}

export type TTask = {
  id: number;
  name: string;
  description: string;
  action_name: string;
  action: string;
  image: string;
  status: string;
  reward: number;
}

export enum TToastType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export type TToast = {
  id: string;
  text: string;
  type: TToastType;
  closeOnTimeout: boolean;
};

export type TShare = {
  id: number;
  name: string;
  description: string;
  image: string;
  risk: string;
  price: number;
  status: string;
  timeout: number;
  count: number;
  income: number;
}

export type TDeposit = {
  id: number;
  name: string;
  percent: number;
  sum: number;
  timeout_at: number;
  status: string;
}

import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`${() => css`
    *, *::after, *::before {
        box-sizing: border-box;
        user-select: none;
        /* stylelint-disable-next-line property-no-unknown */
        user-drag: none;
        touch-action: pan-x pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    html, body, #root {
        margin: 0;
        height: 100%;
        text-rendering: optimizelegibility;
        -webkit-font-smoothing: antialiased;
    }

    body {
        font-family: 'Montserrat', system-ui, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        background: #0B0C11;
        color: #fff;
        color-scheme: dark;
        -webkit-overflow-scrolling: touch;
    }

    #root {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
        pointer-events: none;
    }

    p {
        margin: 0;
    }

    button {
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
        padding: 0;
    }
    
    .system-message {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 16px;
        font-size: 18px;
        color: #1c1c1c;
        text-align: center;
    }
`}`;

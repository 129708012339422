import * as React from 'react';
import * as S from '@components/Mining/Mining.styled';
import { FC, memo } from 'react';
import { getHumanBalance } from '@common/number';
import { selectEarnPassive } from '@src/redux/slices/playerSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Coin from '@src/images/coin.png';
import GrowUp from '@src/images/grow-up.svg';
import HeaderName from '@components/Mining/HeaderName';
import LevelBar from '@components/LevelBar';
import MiningButton from '@components/Mining/MiningButton';
import Passive from '@src/images/passive.svg';
import Percent from '@src/images/percent.svg';
import Question from '@src/images/question.svg';
import Share from '@src/images/share.svg';

const Mining: FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const earnPassive = useSelector(selectEarnPassive);

  return (
    <S.Mining>
      <S.Header>
        <S.HeaderGrid>
          <HeaderName />
          <S.InfoButton to="/info">
            <img
              src={Question}
              alt=""
            />
          </S.InfoButton>
        </S.HeaderGrid>
        <S.HeaderBar>
          <LevelBar />
          <S.Income>
            <img
              width={14}
              src={Coin}
              alt=""
            />
            <S.IncomeLabel>
              Пассивный доход:
            </S.IncomeLabel>
            <S.IncomeValue>
              {getHumanBalance(earnPassive)}
            </S.IncomeValue>
          </S.Income>
        </S.HeaderBar>
      </S.Header>
      <S.Content>
        <S.TapHeader>
          <S.Boost
            onClick={() => impactOccurred('soft')}
            to="/boost"
          >
            <img
              height={24}
              src={GrowUp}
              alt=""
            />
            <S.BoostText>
              Улучшения
            </S.BoostText>
          </S.Boost>
          <S.Boost
            onClick={() => impactOccurred('soft')}
            to="/investments"
          >
            <img
              height={24}
              src={Passive}
              alt=""
            />
            <S.BoostText>
              Пассивный доход
            </S.BoostText>
          </S.Boost>
          <S.Boost
            onClick={() => impactOccurred('soft')}
            to="/exchange"
          >
            <img
              height={24}
              src={Share}
              alt=""
            />
            <S.BoostText>
              Игры на&nbsp;бирже
            </S.BoostText>
          </S.Boost>
          <S.Boost
            onClick={() => impactOccurred('soft')}
            to="/deposit"
          >
            <img
              height={24}
              src={Percent}
              alt=""
            />
            <S.BoostText>
              Вклады
            </S.BoostText>
          </S.Boost>
        </S.TapHeader>
        <S.BalanceWrapper>
          <S.Balance />
        </S.BalanceWrapper>
        <MiningButton />
      </S.Content>
    </S.Mining>
  );
};

export default memo(Mining);

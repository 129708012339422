import * as React from 'react';
import * as S from './Toast.styled';
import { FC, memo, useEffect, useRef } from 'react';
import { TToast } from '@src/app/types';
import { removeToast } from '@src/redux/slices/toastSlice';
import { useAppDispatch } from '@common/hooks';
import Close from '@src/images/close.svg';

type TProps = {
  toast: TToast;
}

const Toast: FC<TProps> = ({ toast }) => {
  const dispatch = useAppDispatch();
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  useEffect(() => {
    if (toast.closeOnTimeout) {
      timeoutId.current = setTimeout(() => {
        dispatch(removeToast(toast.id));
      }, 3000);
    }
  }, [dispatch, toast.closeOnTimeout, toast.id]);

  return (
    <S.Toast>
      {toast.text}
      <S.Button onClick={() => {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
        dispatch(removeToast(toast.id));
      }}
      >
        <img
          width={12}
          src={Close}
          alt=""
        />
      </S.Button>
    </S.Toast>
  );
};

export default memo(Toast);

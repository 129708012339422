import * as React from 'react';
import * as S from './Friends.styled';
import { FC, memo, useCallback, useEffect } from 'react';
import { fetchReferrals, selectReferrals, selectReferralsLoading } from '@src/redux/slices/referralSlice';
import { getHumanBalance } from '@common/number';
import { ranks } from '@common/string';
import { selectReferralLink } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Coin from '@src/images/coin.png';
import Copy from '@src/images/copy.svg';
import FriendGift from '@src/images/friend-gift.png';
import FriendPremiumGift from '@src/images/friend-premium-gift.png';
import ReferralListLoader from '@components/Friends/ReferralListLoader';

const Friends: FC = () => {
  const dispatch = useAppDispatch();
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const playerReferralLink = useSelector(selectReferralLink);
  const referrals = useSelector(selectReferrals);
  const loading = useSelector(selectReferralsLoading);

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchReferrals());
    return () => fetchingDispatch.abort();
  }, [dispatch]);

  const shareLink = useCallback(() => {
    window.ym(98334776, 'reachGoal', 'REFERRAL_SHARE', {
      order_price: 5000,
      currency: 'RUB',
    });

    impactOccurred('soft');
    const shareText = encodeURIComponent('Играй со мной, стань лучшим инвестором и участвуй в развитии компании Роль!'
      + '\n💸 5k монет в качестве первого подарка'
      + '\n🔥 25k монет, если у тебя есть Telegram Premium');
    const shareUrl = encodeURIComponent(`https://t.me/role_coin_bot/role?startapp=${playerReferralLink}`);
    const link = `https://t.me/share/url?url=${shareUrl}&text=${shareText}`;
    Telegram.WebApp.openTelegramLink(link);
  }, [impactOccurred, playerReferralLink]);
  const copyLink = useCallback(() => {
    window.ym(98334776, 'reachGoal', 'REFERRAL_COPY', {
      order_price: 5000,
      currency: 'RUB',
    });

    impactOccurred('soft');
    const link = `https://t.me/role_coin_bot/role?startapp=${playerReferralLink}`;

    navigator.clipboard.writeText(link).then(() => {
      notificationOccurred('success');
    }, err => {
      notificationOccurred('error');
      Telegram.WebApp.showAlert(`Ошибка при копировании ссылки: ${err}`);
    });
  }, [impactOccurred, notificationOccurred, playerReferralLink]);

  return (
    <S.Friends>
      <S.HeaderTitle>Пригласите друзей!</S.HeaderTitle>
      <S.HeaderText>Вы и ваш друг получите бонусы</S.HeaderText>
      <S.HeaderItem>
        <img
          width={64}
          src={FriendGift}
          alt=""
        />
        <S.HeaderItemBody>
          <S.HeaderItemTitle>Пригласить друга</S.HeaderItemTitle>
          <S.HeaderItemText>
            <S.HeaderItemReward>
              +
              <img
                width={14}
                src={Coin}
                alt=""
              />
              5 000
            </S.HeaderItemReward>
            <span>для вас и вашего друга</span>
          </S.HeaderItemText>
        </S.HeaderItemBody>
      </S.HeaderItem>
      <S.HeaderItem>
        <img
          width={64}
          src={FriendPremiumGift}
          alt=""
        />
        <S.HeaderItemBody>
          <S.HeaderItemTitle>Пригласить друга с Telegram Premium:</S.HeaderItemTitle>
          <S.HeaderItemText>
            <S.HeaderItemReward>
              +
              <img
                width={14}
                src={Coin}
                alt=""
              />
              25 000
            </S.HeaderItemReward>
            <span>для вас и вашего друга</span>
          </S.HeaderItemText>
        </S.HeaderItemBody>
      </S.HeaderItem>
      <S.ButtonWrapper>
        <S.ShareButton onClick={shareLink}>
          + Пригласить друга
        </S.ShareButton>
        <S.CopyButton onClick={copyLink}>
          <img
            src={Copy}
            alt=""
          />
        </S.CopyButton>
      </S.ButtonWrapper>
      {loading
        ? <ReferralListLoader />
        : (
          <>
            <S.ReferralTitle>Список ваших друзей ({referrals.length})</S.ReferralTitle>
            <S.ReferralContent>
              {referrals.map((referral, index) => (
                <S.ReferralItem key={index}>
                  <span>{referral.first_name || 'Неизвестный'} ({ranks[referral.level]})</span>
                  <S.ReferralItemIncome>
                    <img
                      width={14}
                      src={Coin}
                      alt=""
                    />
                    {getHumanBalance(referral.earn_passive)}
                  </S.ReferralItemIncome>
                </S.ReferralItem>
              ))}
            </S.ReferralContent>
          </>
        )}
    </S.Friends>
  );
};

export default memo(Friends);

import * as React from 'react';
import * as S from './Deposit.styled';
import { FC, memo, useEffect } from 'react';
import { fetchDeposits,
  selectCurrentDeposit,
  selectDepositLoading,
  selectDeposits } from '@src/redux/slices/depositSlice';
import { getHumanBalance } from '@common/number';
import { selectEarnPassive, selectLevel } from '@src/redux/slices/playerSlice';
import { useAppDispatch } from '@common/hooks';
import { useSelector } from 'react-redux';
import Card from './Card';
import Coin from '@src/images/coin.png';
import DepositGridLoader from '@components/Deposit/DepositGridLoader';
import InvestButton from '@components/Deposit/InvestButton';

const Deposit: FC = () => {
  const dispatch = useAppDispatch();
  const earnPassive = useSelector(selectEarnPassive);
  const level = useSelector(selectLevel);
  const deposits = useSelector(selectDeposits);
  const loading = useSelector(selectDepositLoading);
  const currentDeposit = useSelector(selectCurrentDeposit);

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchDeposits());
    return () => fetchingDispatch?.abort();
  }, [dispatch]);

  return (
    <S.Deposit>
      <S.Balance />
      <S.ContentWrapper>
        <S.Text>
          Возможность увеличения капитала без рисков.
          Процент вклада равен вашему уровню.
          Срок вклада - 24 часа.
        </S.Text>
        {loading
          ? <DepositGridLoader />
          : (
            <>
              <S.SubTitle>Размер вклада</S.SubTitle>
              <S.Grid>
                {deposits.map(deposit => (
                  <Card
                    key={deposit.id}
                    deposit={deposit}
                  />
                ))}
              </S.Grid>
              {currentDeposit && (
                <>
                  <S.Income>
                    <S.IncomeTitle>Через сутки вы получите:</S.IncomeTitle>
                    <S.IncomeValue>
                      <img
                        width={14}
                        src={Coin}
                        alt=""
                      />
                      <span>
                        {getHumanBalance(
                          (Math.round(earnPassive * 24 * currentDeposit.percent) / 100) * level,
                        )}
                      </span>
                    </S.IncomeValue>
                  </S.Income>
                  <InvestButton deposit={currentDeposit} />
                </>
              )}
            </>
          )}
      </S.ContentWrapper>
    </S.Deposit>
  );
};

export default memo(Deposit);

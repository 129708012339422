import Background from '@src/images/bg.svg';
import PlayerBalance from '@components/PlayerBalance';
import styled, { css } from 'styled-components';

export const Deposit = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

export const Balance = styled(PlayerBalance)`
    padding-top: 24px;
    position: sticky;
    top: 0;
    z-index: 0;
    display: flex;
    margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
    position: relative;
    z-index: 5;
    padding: 32px 16px 48px;
    border-radius: 32px 32px 0 0;
    background-color: #1E202D;
    border-top: 2px solid #65450D;
    box-shadow: 0 55px 56px 50px #F29E099E;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    margin-bottom: -24px;
    flex: 1 1 auto;
`;

export const Text = styled.div`
    margin-bottom: 24px;
    font-size: 14px;
`;

export const SubTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 16px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
`;

type TVariantButton = {
  selected: boolean;
}

export const VariantButton = styled.div<TVariantButton>`${({ selected }) => css`
    border-radius: 8px;
    color: #fff;
    background-color: #2B2E42;
    border: 4px solid ${selected ? '#feae03' : '#2B2E42'};
    box-shadow: 2px 3px 10px 0 #00000059;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
`}`;

export const VariantButtonTitle = styled.div`
    background-color: #3b3f5a;
    border-radius: 4px;
    padding: 4px;
    flex: 1 1 auto;
    font-size: 12px;
    text-align: center;
    
    span {
        display: block;
        font-size: 14px;
        font-weight: 700;
    }
`;

export const VariantButtonText = styled.div`
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px 4px 4px;
`;

export const ShareRequirement = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    backdrop-filter: blur(1px);
`;

export const ShareRequirementTimer = styled.div`
    margin-bottom: 12px;
`;

export const Income = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
`;

export const IncomeTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
`;

export const IncomeValue = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
`;

type TBuyButton = {
  disabled: boolean;
}

export const BuyButton = styled.button<TBuyButton>`${({ disabled }) => css`
    width: 100%;
    font-size: 18px;
    padding: 16px 24px;
    color: ${disabled ? '#fff' : '#1F2C53'};
    border-radius: 12px;
    pointer-events: ${disabled ? 'none' : 'auto'};
    background-color: ${disabled ? '#474747' : '#feae03'};
    margin-top: 32px;
`}`;

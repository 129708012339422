import * as React from 'react';
import * as S from './Error.styled';
import { FC, memo } from 'react';

const Error: FC = () => (
  <S.Error>
    <S.Title>Упс! Возникла ошибка</S.Title>
    <S.Message>Пожалуйста, перезапустите приложение и попробуйте снова.</S.Message>
  </S.Error>
);

export default memo(Error);

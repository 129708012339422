import * as React from 'react';
import * as S from './PlayerBalance.styled';
import { FC, memo } from 'react';
import { selectBalanceCoins } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import Coin from '@src/images/coin.png';

type TProps = {
  className?: string;
}

const PlayerBalance: FC<TProps> = ({ className }) => {
  const balance = useSelector(selectBalanceCoins);

  return (
    <S.Balance className={className}>
      <img
        width={35}
        src={Coin}
        alt=""
      />
      <S.BalanceText>
        {Math.round(balance).toLocaleString()}
      </S.BalanceText>
    </S.Balance>
  );
};

export default memo(PlayerBalance);

import Background from '@src/images/bg.svg';
import PlayerBalance from '@components/PlayerBalance';
import styled, { css } from 'styled-components';

export const Investments = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

export const Balance = styled(PlayerBalance)`
    padding-top: 24px;
    position: sticky;
    top: 0;
    z-index: 0;
    display: flex;
    margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
    position: relative;
    z-index: 5;
    padding: 32px 16px 48px;
    border-radius: 32px 32px 0 0;
    background-color: #1E202D;
    border-top: 2px solid #65450D;
    box-shadow: 0 55px 56px 50px #F29E099E;
    background-image: url(${Background});
    background-repeat: repeat;
    background-size: 100% auto;
    margin-bottom: -24px;
    flex: 1 1 auto;
`;

export const CategoryHeader = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: #2B2E42;
    border-radius: 24px;
    margin-bottom: 32px;
    position: sticky;
    top: 8px;
    z-index: 10;
`;

type TCategoryBtn = {
  $isActive: boolean;
}

export const CategoryBtn = styled.button<TCategoryBtn>`${({ $isActive }) => css`
    font-size: 12px;
    line-height: 1;
    padding: 8px;
    color: ${$isActive ? '#FCA100' : '#fff'};
    background-color: ${$isActive ? '#1E202D' : 'transparent'};
    border-radius: 16px;
    box-shadow: ${$isActive ? '0 2px 4px 0 #00000040 inset' : 'none'};
`}`;

export const Content = styled.div`

`;

export const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
`;

export const Card = styled.div`${() => css`
    border-radius: 16px;
    background-color: #2B2E42;
    box-shadow: 2px 3px 10px 0 #00000059;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
`}`;

export const CardImage = styled.img`
    width: 100%;
    display: block;
    border-radius: 16px 16px 0 0;
`;

export const CardName = styled.div`
    font-size: 10px;
    font-weight: 700;
    padding: 10px 16px;
    border-bottom: 1px solid #3B3F59;
    line-height: 1;
    text-align: center;
`;

export const CardContent = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    color: #A1A5BB;
    padding: 6px 16px;
`;

export const CardIncomeLabel = styled.div`
  margin-right: 12px;
    width: 54px;
`;

export const CardIncome = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    img {
        margin-top: 2px;
    }
`;

export const CardFooter = styled.div`
    padding: 10px 16px;
    background-color: #3B3F59;
    border-radius: 0 0 16px 16px;
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 1;
    justify-content: center;
`;

export const CardLevel = styled.div`
    padding: 0 8px;
    border-radius: 8px;
    background-color: #3B3F59;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    position: absolute;
    top: 8px;
    left: 8px;
`;

export const CardRequirement = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 600;
    border-radius: 16px;
    backdrop-filter: blur(1px);
`;

export const CardRequirementTimer = styled.div`
    margin-bottom: 12px;
`;

export const ModalCard = styled.div`
    position: fixed;
    z-index: 120;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const ModalCardBody = styled.div`
    margin-top: auto;
    background-color: #2B2E42;
    border-radius: 24px 24px 0 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: fadeIn 0.3s;

    @keyframes fadeIn {
        0% {
            transform: translate(0, 100%);
        }

        100% {
            transform: translate(0, 0);
        }
    }
`;

export const ModalCardClose = styled.button`
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
`;

export const ModalCardImage = styled.img`
    display: block;
    margin-bottom: 16px;
    border-radius: 16px;
`;

export const ModalCardName = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
`;

export const ModalCardDescription = styled.div`
    font-size: 16px;
    margin-bottom: 16px;
`;

export const ModalCardIncomeLabel = styled.div`
    font-size: 12px;
    margin-bottom: 4px;
`;

export const ModalCardIncome = styled.div`
    font-size: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const ModalCardPrice = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
`;

type TModalCardButton = {
  disabled: boolean;
}

export const ModalCardBtn = styled.button<TModalCardButton>`${({ disabled }) => css`
    width: 100%;
    font-size: 18px;
    padding: 16px 24px;
    color: ${disabled ? '#fff' : '#1F2C53'};
    border-radius: 12px;
    pointer-events: ${disabled ? 'none' : 'auto'};
    background-color: ${disabled ? '#474747' : '#feae03'};
`}`;

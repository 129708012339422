import styled from 'styled-components';

export const Error = styled.div`
    padding: 16px;
    flex: 1 1 auto;
`;

export const Title = styled.div`
    font-size: 32px;
    margin-bottom: 16px;
    text-align: center;
`;

export const Message = styled.div`
    text-align: center;
`;

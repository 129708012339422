import { RootState } from '@src/redux/store';
import { TPlayer } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { getHumanBalance, getProgress } from '@common/number';
import { sendRequest } from '@src/network';

type TPlayerState = {
  player: TPlayer;
  loading: boolean;
  passiveEarn: number;
}

export const playerSlice = createAppSlice({
  name: 'player',
  initialState: {
    player: {},
    loading: true,
    passiveEarn: 0,
  } as TPlayerState,
  reducers: create => ({
    setPlayer: create.reducer<TPlayer>((state, action) => ({
      ...state,
      player: {
        ...state.player,
        ...action.payload,
      },
    })),
    tapEarn: create.reducer(state => {
      state.player.balance_coins += state.player.earn_per_tap;
      state.player.total_coins += state.player.earn_per_tap;
      state.player.available_taps -= state.player.earn_per_tap;
    }),
    passiveEarn: create.reducer(state => {
      const currentEnergy = state.player.available_taps + state.player.taps_recover_per_sec;
      state.player.available_taps = currentEnergy > state.player.max_taps ? state.player.max_taps : currentEnergy;
      state.player.balance_coins += state.player.earn_passive_per_sec;
      state.player.total_coins += state.player.earn_passive_per_sec;
    }),
    fetchPlayer: create.asyncThunk(
      async (_, { signal }) => sendRequest<TPlayer>('/player', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => ({
          ...state,
          player: {
            ...state.player,
            ...action.payload,
          },
          loading: false,
        }),
      },
    ),
  }),
  selectors: {
    selectPlayer: state => state.player,
    selectPlayerLoading: state => state.loading,
    selectFirstName: state => state.player.first_name,
    selectLastName: state => state.player.last_name,
    selectUsername: state => state.player.username,
    selectBalanceCoins: state => state.player.balance_coins,
    selectTotalCoins: state => state.player.total_coins,
    selectLevel: state => state.player.level,
    selectAvailableTaps: state => state.player.available_taps,
    selectMaxTaps: state => state.player.max_taps,
    selectEarnPerTap: state => state.player.earn_per_tap,
    selectEarnPassive: state => state.player.earn_passive,
    selectEarnPassivePerSec: state => state.player.earn_passive_per_sec,
    selectLastPassiveEarn: state => state.player.last_passive_earn,
    selectTapsRecoverPerSec: state => state.player.taps_recover_per_sec,
    selectReferralLink: state => state.player.referral_link,
    selectDailyStreak: state => state.player.daily_streak,
    selectTapLevel: state => state.player.tap_level,
    selectEnergyLevel: state => state.player.energy_level,
    selectEnergyRecoverLevel: state => state.player.energy_recover_level,
    selectPassiveEarnLevel: state => state.player.passive_earn_level,
    selectMaxPassiveEarnTime: state => state.player.max_passive_earn_time,
  },
});

export const checkEnoughMoney = createSelector(
  [
    (state: RootState) => state.player.player.balance_coins,
    (_, price: number) => price,
  ],
  (balance, price) => balance >= price,
);

export const selectProgress = createSelector(
  [
    (state: RootState) => state.player.player.total_coins,
    (state: RootState) => state.player.player.level,
  ],
  (totalCoins, level) => getProgress(totalCoins, level),
);

export const selectRatingProgress = createSelector(
  [
    (state: RootState) => state.player.player.total_coins,
    (state: RootState) => state.player.player.level,
    (_, chosenLevel: number) => chosenLevel,
  ],
  (totalCoins, level, chosenLevel) => {
    if (level > chosenLevel) {
      return 100;
    }
    if (level < chosenLevel) {
      return 0;
    }
    return getProgress(totalCoins, chosenLevel);
  },
);

export const selectHumanTotalCoins = createSelector(
  [
    (state: RootState) => state.player.player.total_coins,
  ],
  totalCoins => getHumanBalance(totalCoins),
);

export const {
  setPlayer,
  tapEarn,
  passiveEarn,
  fetchPlayer,
} = playerSlice.actions;

export const {
  selectPlayerLoading,
  selectPlayer,
  selectFirstName,
  selectLastName,
  selectUsername,
  selectBalanceCoins,
  selectTotalCoins,
  selectLevel,
  selectAvailableTaps,
  selectMaxTaps,
  selectEarnPerTap,
  selectEarnPassive,
  selectEarnPassivePerSec,
  selectLastPassiveEarn,
  selectTapsRecoverPerSec,
  selectReferralLink,
  selectDailyStreak,
  selectTapLevel,
  selectEnergyLevel,
  selectEnergyRecoverLevel,
  selectPassiveEarnLevel,
  selectMaxPassiveEarnTime,
} = playerSlice.selectors;

export default playerSlice.reducer;

import * as React from 'react';
import * as S from './Time.styled';
import { FC, memo } from 'react';
import Time from '@src/images/time.svg';

type TProps = {
  timer: string;
}

const Timer: FC<TProps> = ({ timer }) => (
  <S.Timer>
    <img
      width={12}
      src={Time}
      alt=""
    />
    {timer}
  </S.Timer>
);

export default memo(Timer);

import * as S from '@components/Exchange/Exchange.styled';
import { getHumanBalance } from '@common/number';
import { selectBalanceCoins } from '@src/redux/slices/playerSlice';
import { selectCurrentShare, setCurrentShare } from '@src/redux/slices/shareSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import BuyButton from '@components/Exchange/BuyButton';
import Close from '@src/images/close.svg';
import Coin from '@src/images/coin.png';
import Minus from '@src/images/minus.svg';
import Plus from '@src/images/plus.svg';
import React, { FC, memo, useEffect, useState } from 'react';

const getChance = (risk: string) => {
  if (risk === 'EASY') {
    return 90;
  }
  if (risk === 'NORMAL') {
    return 50;
  }
  return 20;
};

const getIncome = (risk: string, price: number, count: number) => {
  if (risk === 'EASY') {
    return getHumanBalance(price * count * 0.15);
  }
  if (risk === 'NORMAL') {
    return getHumanBalance(price * count * 1.2);
  }
  if (risk === 'HARD') {
    return getHumanBalance(price * count * 4.8);
  }
  return getHumanBalance(price * count);
};

const ShareModal: FC = () => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const balance = useSelector(selectBalanceCoins);
  const currentShare = useSelector(selectCurrentShare);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [currentShare]);

  return currentShare ? (
    <S.ModalCard>
      <S.ModalCardBody>
        <S.ModalCardClose onClick={() => {
          impactOccurred('soft');
          dispatch(setCurrentShare(undefined));
        }}
        >
          <img
            width={14}
            src={Close}
            alt=""
          />
        </S.ModalCardClose>
        <S.ModalCardName>{currentShare.name}</S.ModalCardName>
        {currentShare.description && (
          <S.ModalCardDescription>{currentShare.description}</S.ModalCardDescription>
        )}
        <S.ModalCardSubTitle>Цена акции</S.ModalCardSubTitle>
        <S.ModalCardPrice>
          <img
            width={14}
            src={Coin}
            alt=""
          />
          <span>{getHumanBalance(currentShare.price)}</span>
        </S.ModalCardPrice>
        {currentShare.status === 'IN_PROCESS'
          ? (
            <>
              <S.ModalCardSubTitle>Куплено акций</S.ModalCardSubTitle>
              <S.Count>
                <S.CountText>{currentShare.count}</S.CountText>
              </S.Count>
              <S.ModalCardSubTitle>Сумма</S.ModalCardSubTitle>
              <S.ModalCardPrice>
                <img
                  width={14}
                  src={Coin}
                  alt=""
                />
                <span>{getHumanBalance(currentShare.price * currentShare.count)}</span>
              </S.ModalCardPrice>
              <S.ModalCardSubTitle>Возможная прибыль</S.ModalCardSubTitle>
              <S.ModalCardPrice>
                <img
                  width={14}
                  src={Coin}
                  alt=""
                />
                <span>{getIncome(currentShare.risk, currentShare.price, currentShare.count)}</span>
              </S.ModalCardPrice>
            </>
          )
          : (
            <>
              <S.ModalCardSubTitle>Количество акций</S.ModalCardSubTitle>
              <S.Count>
                <S.CountBtn
                  $disabled={count <= 1}
                  onClick={() => {
                    impactOccurred('soft');
                    setCount(current => current - 1);
                  }}
                >
                  <img
                    src={Minus}
                    alt=""
                  />
                </S.CountBtn>
                <S.CountText>{count}</S.CountText>
                <S.CountBtn
                  $disabled={currentShare.price * (count + 1) > balance}
                  onClick={() => {
                    impactOccurred('soft');
                    setCount(current => current + 1);
                  }}
                >
                  <img
                    src={Plus}
                    alt=""
                  />
                </S.CountBtn>
              </S.Count>
              <S.ModalCardSubTitle>Сумма</S.ModalCardSubTitle>
              <S.ModalCardPrice>
                <img
                  width={14}
                  src={Coin}
                  alt=""
                />
                <span>{getHumanBalance(currentShare.price * count)}</span>
              </S.ModalCardPrice>
              <S.ModalCardSubTitle>Возможная прибыль</S.ModalCardSubTitle>
              <S.ModalCardPrice>
                <img
                  width={14}
                  src={Coin}
                  alt=""
                />
                <span>{getIncome(currentShare.risk, currentShare.price, count)}</span>
              </S.ModalCardPrice>
            </>
          )}
        <S.ModalCardSubTitle>Шанс получить прибыль</S.ModalCardSubTitle>
        <S.ModalCardPrice>
          {getChance(currentShare.risk)}%
        </S.ModalCardPrice>
        <BuyButton
          share={currentShare}
          count={count}
        />
      </S.ModalCardBody>
    </S.ModalCard>
  ) : null;
};

export default memo(ShareModal);

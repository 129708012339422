import * as React from 'react';
import * as S from '@components/Deposit/Deposit.styled';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { TDeposit } from '@src/app/types';
import { TInvestDeposit, investDeposit, selectInvestingDeposit } from '@src/redux/slices/depositSlice';
import { checkEnoughMoney, selectEarnPassive, selectLevel, setPlayer } from '@src/redux/slices/playerSlice';
import { useAppDispatch, useHapticFeedback, useTimer } from '@common/hooks';
import { useSelector } from 'react-redux';
import Timer from '@components/Timer';

type TProps = {
  deposit: TDeposit;
}

type TButtonState = {
  text: string | React.JSX.Element;
  disabled: boolean;
}

const InvestButton: FC<TProps> = ({ deposit }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const earnPassive = useSelector(selectEarnPassive);
  const level = useSelector(selectLevel);
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, earnPassive * 24 * deposit.percent));
  const timer = useTimer(deposit.timeout_at);
  const investingDeposit = useSelector(selectInvestingDeposit);
  const [buttonState, setButtonState] = useState<TButtonState>({
    text: `Вложить под ${level}%`,
    disabled: true,
  });

  const investCallback = useCallback(() => {
    impactOccurred('soft');

    const fetchingDispatch = dispatch(investDeposit({
      id: deposit.id,
    }));
    fetchingDispatch.then(response => {
      window.ym(98334776, 'reachGoal', 'DEPOSIT_INVEST', {
        order_price: earnPassive * 24 * deposit.percent,
        currency: 'RUB',
      });

      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          purchase: {
            actionField: {
              id: new Date().getTime(),
            },
            products: [{
              id: deposit.id,
              name: deposit.name,
              price: earnPassive * 24 * deposit.percent,
              category: 'Вклад',
            }],
          },
        },
      });

      const responsePlayer = (response.payload as TInvestDeposit)?.player;
      if (responsePlayer) {
        dispatch(setPlayer(responsePlayer));
      }
    });
  }, [impactOccurred, dispatch, deposit.id, deposit.percent, deposit.name, earnPassive]);

  useEffect(() => {
    if (deposit.timeout_at && timer) {
      setButtonState({
        text: <Timer timer={timer} />,
        disabled: true,
      });
    } else if (investingDeposit) {
      setButtonState({
        text: 'Инвестируется',
        disabled: true,
      });
    } else if (deposit.status === 'IN_PROCESS') {
      setButtonState({
        text: 'Получить доход',
        disabled: false,
      });
    } else if (earnPassive < 10000) {
      setButtonState({
        text: 'Недостаточный пассивный доход',
        disabled: true,
      });
    } else if (!hasEnoughMoney) {
      setButtonState({
        text: 'Недостаточно средств',
        disabled: true,
      });
    } else {
      setButtonState({
        text: `Вложить под ${level}%`,
        disabled: false,
      });
    }
  }, [hasEnoughMoney, timer, deposit.timeout_at, deposit.status, investingDeposit, level, earnPassive]);

  return (
    <S.BuyButton
      disabled={buttonState.disabled}
      onClick={() => {
        if (!buttonState.disabled) {
          investCallback();
        }
      }}
    >
      {buttonState.text}
    </S.BuyButton>
  );
};

export default memo(InvestButton);

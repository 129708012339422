import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { FC, memo, useEffect } from 'react';
import { fetchCategories,
  selectCategories,
  selectCurrentCategoryId,
  selectLoading, setCurrentCategoryId } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Card from '@components/Investment/Card';
import CardGridLoader from '@components/Investment/CardGridLoader';
import CardModal from '@components/Investment/CardModal';

const Investments: FC = () => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const categories = useSelector(selectCategories);
  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchCategories());
    return () => fetchingDispatch?.abort();
  }, [dispatch]);

  return (
    <S.Investments>
      <S.Balance />
      <S.ContentWrapper>
        {!loading && (
          <S.CategoryHeader>
            {categories.map(category => (
              <S.CategoryBtn
                key={category.id}
                $isActive={currentCategoryId === category.id}
                onClick={() => {
                  impactOccurred('soft');
                  dispatch(setCurrentCategoryId(category.id));
                  window.ym(98334776, 'hit', `/investments/${category.id}`, {});
                }}
              >
                {category.name}
              </S.CategoryBtn>
            ))}
          </S.CategoryHeader>
        )}
        <S.Content>
          {loading ? (
            <CardGridLoader />
          ) : (
            <>
              {categories.filter(category => category.id === currentCategoryId).map(category => (
                <S.CategoryGrid key={category.id}>
                  {category.cards.map(card => (
                    <Card
                      key={card.id}
                      card={card}
                    />
                  ))}
                </S.CategoryGrid>
              ))}
            </>
          )}
        </S.Content>
      </S.ContentWrapper>
      <CardModal />
    </S.Investments>
  );
};

export default memo(Investments);

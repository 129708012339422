import * as S from '@components/Navigation/Navigation.styled';
import { useHapticFeedback } from '@common/hooks';
import { useLocation } from 'react-router-dom';
import React, { FC, memo, useEffect } from 'react';

const Navigation: FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      Telegram.WebApp.BackButton.show();
    } else {
      Telegram.WebApp.BackButton.hide();
    }
    window.ym(98334776, 'hit', location.pathname, {});
  }, [location]);

  return (
    <S.Footer>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/"
      >Капитал
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/quests"
      >Задания
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/friends"
      >Друзья
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/rating"
      >Рейтинг
      </S.Link>
    </S.Footer>
  );
};

export default memo(Navigation);

import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { FC, memo } from 'react';
import { getHumanBalance } from '@common/number';
import { selectCurrentCard, setCurrentCard } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import BlankCard from '@src/images/blank-card.jpg';
import BuyButton from '@components/Investment/BuyButton';
import Close from '@src/images/close.svg';
import Coin from '@src/images/coin.png';

const CardModal: FC = () => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();
  const currentCard = useSelector(selectCurrentCard);

  return currentCard ? (
    <S.ModalCard>
      <S.ModalCardBody>
        <S.ModalCardClose onClick={() => {
          impactOccurred('soft');
          dispatch(setCurrentCard(undefined));
        }}
        >
          <img
            width={14}
            src={Close}
            alt=""
          />
        </S.ModalCardClose>
        <S.ModalCardImage src={currentCard.image ? `${currentCard.image}?v=${window.appVersion}` : BlankCard} />
        <S.ModalCardName>{currentCard.name}</S.ModalCardName>
        {currentCard.description && (
          <S.ModalCardDescription>{currentCard.description}</S.ModalCardDescription>
        )}
        <S.ModalCardIncomeLabel>Доход в&nbsp;час</S.ModalCardIncomeLabel>
        <S.ModalCardIncome>
          <span>+</span>
          <img
            width={14}
            src={Coin}
            alt=""
          />
          <span>{getHumanBalance(currentCard.income)}</span>
        </S.ModalCardIncome>
        <S.ModalCardPrice>
          <img
            width={14}
            src={Coin}
            alt=""
          />
          <span>{getHumanBalance(currentCard.price)}</span>
        </S.ModalCardPrice>
        <BuyButton card={currentCard} />
      </S.ModalCardBody>
    </S.ModalCard>
  ) : null;
};

export default memo(CardModal);

import { TPlayer, TUpgradeCard, TUpgradeCategory } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TCardState = {
  currentCard?: TUpgradeCard;
  categories: Array<TUpgradeCategory>;
  currentCategoryId?: number;
  loading: boolean;
  buyingCard: boolean;
}

export type TBuyCard = {
  categories: Array<TUpgradeCategory>;
  player: TPlayer;
};

export const cardSlice = createAppSlice({
  name: 'card',
  initialState: {
    currentCard: undefined,
    categories: [],
    loading: true,
    buyingCard: false,
  } as TCardState,
  reducers: create => ({
    setCurrentCard: create.reducer<TUpgradeCard | undefined>((state, action) => {
      if (action.payload) {
        window.dataLayer.push({
          ecommerce: {
            currencyCode: 'RUB',
            detail: {
              products: [
                {
                  id: action.payload.id,
                  name: action.payload.name,
                  price: action.payload.price,
                  category: action.payload.category,
                  variant: action.payload.level,
                },
              ],
            },
          },
        });
      }
      state.currentCard = action.payload;
    }),
    setCategories: create.reducer<Array<TUpgradeCategory>>((state, action) => {
      state.categories = action.payload;
    }),
    setCurrentCategoryId: create.reducer<number>((state, action) => {
      state.currentCategoryId = action.payload;
    }),
    fetchCategories: create.asyncThunk(
      async (_, { signal }) => sendRequest<Array<TUpgradeCategory>>('/card', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
          state.categories = [];
        },
        fulfilled: (state, action) => {
          state.categories = action.payload || [];
          state.currentCategoryId = action.payload ? action.payload[0].id : undefined;
          state.loading = false;
        },
      },
    ),
    buyCard: create.asyncThunk(
      async (buyingCard: TUpgradeCard, { signal }) => {
        window.ym(98334776, 'reachGoal', 'CARD_BUY', {
          order_price: buyingCard.price,
          currency: 'RUB',
        });
        window.dataLayer.push({
          ecommerce: {
            currencyCode: 'RUB',
            purchase: {
              actionField: {
                id: new Date().getTime(),
              },
              products: [
                {
                  id: buyingCard.id,
                  name: buyingCard.name,
                  price: buyingCard.price,
                  category: buyingCard.category,
                  variant: buyingCard.level,
                },
              ],
            },
          },
        });

        return sendRequest<TBuyCard>('/card/buy', {
          signal,
          method: 'POST',
          body: {
            id: buyingCard.id,
          },
          showToast: true,
        });
      },
      {
        pending: state => {
          state.buyingCard = true;
        },
        rejected: state => {
          state.buyingCard = false;
        },
        fulfilled: (state, action) => {
          if (action.payload?.categories) {
            state.categories = action.payload.categories;
          }
          state.currentCard = undefined;
          state.buyingCard = false;
        },
      },
    ),
  }),
  selectors: {
    selectCurrentCard: state => state.currentCard,
    selectCurrentCategoryId: state => state.currentCategoryId,
    selectCategories: state => state.categories,
    selectLoading: state => state.loading,
    selectBuyingCard: state => state.buyingCard,
  },
});

export const {
  setCurrentCard,
  fetchCategories,
  setCategories,
  setCurrentCategoryId,
  buyCard,
} = cardSlice.actions;

export const {
  selectCurrentCard,
  selectCurrentCategoryId,
  selectCategories,
  selectLoading,
  selectBuyingCard,
} = cardSlice.selectors;

export default cardSlice.reducer;
